import React from "react";
import Header from "./Header/Header";
import "../style/Projects.scss";
import Footer from "./Footer";

export default function Projects() {
  return (
    <>
      <Header />
      <h1 className="text-center font-bold text-4xl m-4">Select Project</h1>
      <h6 className="about-project-page">
        “I am a skilled web developer with experience in building front-end,
        back-end, MERN, and React projects. I am proficient in JavaScript,
        React,Angular, Next, Node.js, MongoDB, and other web development technologies. I have
        built several web applications using these technologies, I am constantly
        expanding my skillset and staying up-to-date with the latest tools and
        trends to deliver top-notch work that is both beautiful and functional.
        I am a quick learner and I am always up for a challenge.”
      </h6>
      <div className="all-projects">
        {/* -----------------------------------------------------------------Front-End Projects */}
        <p className="title">Front-End Projects</p>
        <div class="project-container frontend-projects flex ustify-start">
          <a href="https://ui-vivek.github.io/Todolist/" alt="To-Do List">
            <div class="project">
              <img src={require("../Images/todolist.png")} alt="To-Do List" />
              <div class="description">
                To-Do List
                <br />
                <p>
                  HTML,CSS,JS
                  <br />
                  Local storage
                  <br />
                  Different Modes
                </p>
              </div>
            </div>
          </a>
          <a href="https://ui-vivek.github.io/IMDB_clone/" alt="IMDB clone">
            <div class="project">
              <img src={require("../Images/imdb.jpg")} alt="IMDB Clone" />
              <div class="description">
                IMDB Clone
                <br />
                <p>
                  Search Movies
                  <br />
                  API Fetching
                  <br />
                  Add to Favorites
                </p>
              </div>
            </div>
          </a>
          <a href="https://ui-vivek.github.io/Search-Bar/" alt="Search-Bar">
            <div class="project">
              <img src={require("../Images/search_bar.png")} alt="Search-Bar" />
              <div class="description">
                Search-Bar
                <p>
                  GitHub Hosting
                  <br />
                  HTML,CSS,JavaScript
                  <br />
                  Top 50 Dev.
                </p>
              </div>
            </div>
          </a>
          <a href="https://ui-vivek.github.io/Music-CN/">
            <div class="project">
              <img
                src={require("../Images/music_website.jpg")}
                alt="Music Website"
              />
              <div class="description">
                Music Website
                <br />
                <p>
                  HTML,CSS
                  <br />
                  Mockup
                  <br />
                  Github Hosting
                </p>
              </div>
            </div>
          </a>
        </div>
        {/* -----------------------------------------------------------------React Projects */}
        <p className="title">React Projects</p>
        <div class="project-container react-projects flex ustify-start">
          <a href="https://weather-vivek-singh.netlify.app/">
            <div class="project">
              <img src={require("../Images/weather.png")} alt="weather App" />
              <div class="description">
                Weather App
                <br />
                <p>
                  Search Location
                  <br />
                  Geo Location
                  <br />
                  5-days weather
                  <br />
                  Responsive
                </p>
              </div>
            </div>
          </a>
          <a href="https://react-app-phonebook.netlify.app/">
            <div class="project">
              <img src={require("../Images/phonebook.png")} alt="PhoneBook" />
              <div class="description">
                PhoneBook
                <br />
                <p>
                  React
                  <br />
                  Local Storage
                  <br />
                  Cool Ui
                </p>
              </div>
            </div>
          </a>
          <a href="https://firepost.netlify.app/">
            <div class="project">
              <img
                src={require("../Images/createPost.jpg")}
                alt="Create Blog"
              />
              <div class="description">
                Create Blog
                <br />
                <p>
                  Firebase
                  <br />
                  Create Post
                  <br />
                  React Hooks
                </p>
              </div>
            </div>
          </a>
          <a href="https://cart-19na.onrender.com/">
            <div class="project">
              <img src={require("../Images/cart.png")} alt="Shopping Cart" />
              <div class="description">
                Shopping Cart
                <br />
                <p>
                  FireBase
                  <br />
                  Router v6
                  <br />
                  Tailwind Css
                </p>
              </div>
            </div>
          </a>
          <a
            href="https://vs-react-calculator-app.netlify.app/"
            alt="calculator app"
          >
            <div class="project">
              <img
                src={require("../Images/calculator.png")}
                alt="Calculator App"
              />
              <div class="description">
                Calculator App
                <br />
                <p>
                  useState
                  <br />
                  JavaScript
                  <br />
                  CSS
                </p>
              </div>
            </div>
          </a>
        </div>
        {/* -----------------------------------------------------------------MERN-Stack Projects */}
        <p className="title">MERN-Stack</p>
        <div class="project-container mearn-projects flex ustify-start">
          <a href="https://shy-plum-lemming-yoke.cyclic.app/" alt="Eva AI">
            <div class="project">
              <img src={require("../Images/Eva.jpg")} alt="chatgpt clone" />
              <div class="description">
                <br />
                EVA [AI]
                <br />
                <p>
                  MongoDB
                  <br />
                  Express
                  <br />
                  React
                  <br />
                  Node.js
                </p>
              </div>
            </div>
          </a>

          <div class="project">
            <img src={require("../Images/Ecommerce.jpg")} alt="E-Commerce" />
            <div class="description">
              <span style={{ backgroundColor: "red" }}> Coming Soon...</span>
              <br />
              E-Commerce
              <br />
              <p>
                MongoDB
                <br />
                Express
                <br />
                React
                <br />
                Node.js
              </p>
            </div>
          </div>
        </div>
        {/* -----------------------------------------------------------------MEAN-Stack Projects */}
        <p className="title">MEAN-Stack</p>
        <div class="project-container mearn-projects flex ustify-start">
          <a href="https://vs-my-bot-chatbot.netlify.app/" alt="Chat Bot">
            <div class="project">
              <img src={require("../Images/ChatBotImage.jpeg")} alt="Chat Bot" />
              <div class="description">
                <br />
                Chat Bot
                <br />
                <p>
                  Angular
                  <br />
                  Nest.JS
                  <br />
                  Google Gemini
                  <br />
                  MongoDB
                </p>
              </div>
            </div>
          </a>
        </div>
        {/* -----------------------------------------------------------------Back-End Projects */}
        <p className="title">Back-End Projects</p>
        <div class="project-container backend-projects flex ustify-start">
          <a href="https://github.com/ui-vivek/The-Gym" alt="the Gym">
            <div class="project">
              <img src={require("../Images/gym.png")} alt="The Gym website" />
              <div class="description">
                The Gym
                <br />
                <p>
                  Node.js
                  <br />
                  Express
                  <br />
                  Pug
                  <br />
                  Fs Module
                </p>
              </div>
            </div>
          </a>
          <a href="https://github.com/ui-vivek/Zable" alt="Zable">
            <div class="project">
              <img
                src={require("../Images/zable.png")}
                alt="Social Media website"
              />
              <div class="description">
                Zable(Social Media)
                <br />
                <p>
                  Passport.js
                  <br />
                  MongoDB
                  <br />
                  Ejs,Sass,
                  <br />
                  Nodemailer,socket.io
                </p>
              </div>
            </div>
          </a>
        </div>

        {/* -----------------------------------------------------------------UI/UX Projects */}
        <p className="title">Other Projects (UI/UX)</p>
        <div class="project-container uiux-projects flex ustify-start">
          <a
            href="https://cuvivek1557.wixsite.com/v2s5/services-6"
            alt="setting app ui"
          >
            <div class="project">
              <img
                src={require("../Images/setting.jpeg")}
                alt="setting app ui"
              />
              <div class="description">
                Setting App UI
                <br />
                <p>
                  Interaction Design
                  <br />
                  Figma
                  <br />
                  UX research
                </p>
              </div>
            </div>
          </a>
          <a href="https://cuvivek1557.wixsite.com/v2s5/copy-of-project-1">
            <div class="project">
              <img
                src={require("../Images/Creative rats.png")}
                alt="website app ui"
              />
              <div class="description">
                Creative rats
                <br />
                <p>
                  Interaction Design
                  <br />
                  Figma
                  <br />
                  No code Website
                </p>
              </div>
            </div>
          </a>
          <a
            href="https://cuvivek1557.wixsite.com/v2s5/copy-of-project-2"
            alt="music app ui"
          >
            <div class="project">
              <img src={require("../Images/music.png")} alt="Music app ui" />
              <div class="description">
                Music App UI
                <br />
                <p>
                  Visual Design
                  <br />
                  Mockup
                  <br />
                  No code Website
                </p>
              </div>
            </div>
          </a>
          <a
            href="https://www.figma.com/proto/rkuZXe78LNrWswA38cLZvf/Untitled?node-id=60-18950&starting-point-node-id=60%3A18950&scaling=min-zoom"
            alt="bunkbuddy"
          >
            <div class="project">
              <img
                src={require("../Images/bunkbuddy.png")}
                alt="accumedation app ui"
              />
              <div class="description">
                Bunkbuddy
                <br />
                <p>
                  Visual Design
                  <br />
                  Hotel Booking
                  <br />
                  Traveling
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}
