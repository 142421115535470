import React from "react";
import Header from "./Header/Header";
import "../style/Home.scss";
import Typewriter from "typewriter-effect";
export default function Home() {
  return (
    <>
      <Header />
      <div className="hero min-h-screen bg-base-200 flex justify-around ">
        <div className="hero-content flex-col lg:flex-row-reverse">
          <div className="right-section">
            <img
              id="my-photo"
              src="https://files.codingninjas.com/profile236766498c77d6f39b7da1c4e0c22ffee73e0cf.png"
              alt=""
              className=" max-w-sm rounded-lg shadow-2xl  "
            />
          </div>
          <div className="left-section">
            <h1 className="text-5xl font-bold">Vivek Singh</h1>
            <h4 className="typed py-6 text-3xl font-bold">
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 40,
                  strings: [
                    "Web developer.",
                    // "UI/UX designer.",
                    "Full-Stack Developer.",
                    "Software Engineer.",
                    "Java Programmer.",
                  ],
                }}
              />
            </h4>
            <p className="details pb-6 w-3/4">
            I am a software engineer with extensive experience developing web applications using the MEAN and MERN stacks. My proficiency in JavaScript frameworks such as React and Angular allows me to provide a comprehensive skill set for diverse project requirements. I have experience with both the front-end and back-end aspects of web development, and I am proficient in writing both server-side and client-side code. My strong understanding of the principles of software engineering allows me to create applications that are both reliable and scalable. I am passionate about my work and am always looking for new challenges.
            </p>
            <div className="btns-group ">
              <a href="https://github.com/ui-vivek" alt="github">
              <button className="btn btn-circle mr-3 bg-cyan-600 shadow-lg shadow-cyan-500/50">
                <i className="bx bxl-github bx-sm"></i>
              </button>
              </a>
              <a href="https://www.linkedin.com/in/uivivek/" alt="linkedin">
              <button className="btn btn-circle mr-3 bg-cyan-600 shadow-lg shadow-cyan-500/50">
                <i className="bx bxl-linkedin bx-sm"></i>
              </button>
              </a>
              <a href="https://twitter.com/Vivek_singh_25" alt="twitter">
              <button className="btn btn-circle mr-3 bg-cyan-600 shadow-lg shadow-cyan-500/50">
                <i className="bx bxl-twitter bx-sm"></i>
              </button>
              </a>
              <a href="https://www.instagram.com/ui_vivek/" alt="instagram">
              <button className="btn btn-circle mr-3 bg-cyan-600 shadow-lg shadow-cyan-500/50">
                <i className="bx bxl-instagram bx-sm"></i>
              </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* //------------------------------------- */}
      <div className="custom-shape-divider-bottom-1678985483 style">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
          alt="logo"
        >
          <path
            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
            opacity=".25"
            className="shape-fill"
            alt="logo"
          ></path>
          <path
            d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
            opacity=".5"
            className="shape-fill"
            alt="logo"
          ></path>
          <path
            d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
            className="shape-fill"
            alt="logo"
          ></path>
        </svg>
      </div>
    </>
  );
}
